@import "minima";

// Responsive video container
.video-container {
  position: relative;
  padding-bottom: 56.25%; // 16:9 aspect ratio
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 1em;
}

.video-container iframe,
.video-container object,
.video-container embed,
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Direct video element styling
video {
  max-width: 100%;
  height: auto;
  margin-bottom: 1em;
} 